const Wave = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 left-0 w-full z-0"
        style={{ zIndex: -1 }}
      >
        <path
          fill="#A3A1A1"
          fill-opacity="0.2"
          d="M0,64L30,80C60,96,120,128,180,133.3C240,139,300,117,360,138.7C420,160,480,224,540,250.7C600,277,660,267,720,234.7C780,203,840,149,900,128C960,107,1020,117,1080,144C1140,171,1200,213,1260,218.7C1320,224,1380,192,1410,176L1440,160L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        className="absolute bottom-0 left-0 w-full z-0"
        style={{ zIndex: -1 }}
      >
        <path
          fill="#A3A1A"
          fill-opacity="0.1"
          d="M0,32L12,32C24,32,48,32,72,37.3C96,43,120,53,144,85.3C168,117,192,171,216,197.3C240,224,264,224,288,218.7C312,213,336,203,360,208C384,213,408,235,432,245.3C456,256,480,256,504,261.3C528,267,552,277,576,266.7C600,256,624,224,648,197.3C672,171,696,149,720,128C744,107,768,85,792,69.3C816,53,840,43,864,64C888,85,912,139,936,154.7C960,171,984,149,1008,165.3C1032,181,1056,235,1080,234.7C1104,235,1128,181,1152,181.3C1176,181,1200,235,1224,245.3C1248,256,1272,224,1296,192C1320,160,1344,128,1368,122.7C1392,117,1416,139,1428,149.3L1440,160L1440,320L1428,320C1416,320,1392,320,1368,320C1344,320,1320,320,1296,320C1272,320,1248,320,1224,320C1200,320,1176,320,1152,320C1128,320,1104,320,1080,320C1056,320,1032,320,1008,320C984,320,960,320,936,320C912,320,888,320,864,320C840,320,816,320,792,320C768,320,744,320,720,320C696,320,672,320,648,320C624,320,600,320,576,320C552,320,528,320,504,320C480,320,456,320,432,320C408,320,384,320,360,320C336,320,312,320,288,320C264,320,240,320,216,320C192,320,168,320,144,320C120,320,96,320,72,320C48,320,24,320,12,320L0,320Z"
        ></path>
      </svg>
    </>
  );
};

const GitHubIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="20"
    height="20"
    imageRendering="optimizeQuality"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path d="M12 .297c-6.627 0-12 5.373-12 12 0 5.303 3.438 9.8 8.207 11.388.6.11.827-.26.827-.578 0-.287-.01-1.243-.01-2.25-3.338.724-4.043-1.607-4.043-1.607-.546-1.384-1.333-1.754-1.333-1.754-1.09-.745.083-.73.083-.73 1.205.084 1.838 1.236 1.838 1.236 1.07 1.83 2.805 1.302 3.49.996.108-.775.42-1.302.762-1.604-2.665-.303-5.467-1.332-5.467-5.93 0-1.313.47-2.387 1.236-3.224-.124-.303-.536-1.53.117-3.187 0 0 1.007-.322 3.299 1.229a11.47 11.47 0 013.003-.403c1.021.004 2.052.138 3.003.403 2.292-1.551 3.299-1.229 3.299-1.229.653 1.657.241 2.884.118 3.187.766.837 1.236 1.911 1.236 3.224 0 4.608-2.805 5.62-5.477 5.922.43.372.815 1.102.815 2.224 0 1.607-.015 2.91-.015 3.305 0 .318.226.694.83.577A12.001 12.001 0 0024 12c0-6.627-5.373-12-12-12z" />
  </svg>
);

const Twitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="currentColor"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 640 640"
  >
    <path d="M640.012 121.513c-23.528 10.524-48.875 17.516-75.343 20.634 27.118-16.24 47.858-41.977 57.756-72.615-25.347 14.988-53.516 25.985-83.363 31.866-24-25.5-58.087-41.35-95.848-41.35-72.508 0-131.21 58.736-131.21 131.198 0 10.228 1.134 20.232 3.355 29.882-109.1-5.528-205.821-57.757-270.57-137.222a131.423 131.423 0 0 0-17.764 66c0 45.497 23.102 85.738 58.347 109.207-21.508-.638-41.74-6.638-59.505-16.359v1.642c0 63.627 45.225 116.718 105.32 128.718-11.008 2.988-22.63 4.642-34.606 4.642-8.48 0-16.654-.874-24.78-2.35 16.783 52.11 65.233 90.095 122.612 91.205-44.989 35.245-101.493 56.233-163.09 56.233-10.63 0-20.988-.65-31.334-1.89 58.229 37.359 127.206 58.997 201.31 58.997 241.42 0 373.552-200.069 373.552-373.54 0-5.764-.13-11.35-.366-16.996 25.642-18.343 47.87-41.493 65.469-67.844l.059-.059z" />
  </svg>
);

const LinkedIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-linkedin"
    viewBox="0 0 16 16"
  >
    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
  </svg>
);

export { Wave, GitHubIcon, Twitter, LinkedIn };
